<script>
// import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import store from "@/state/store";

export default {
  data() {
    return {
      emp_job_titles: [],
      emp_sectors: [],
      EmpJobTitles: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchEmpJobTitles: null,
      EmpJobTitlesSearchMode: false,
      // appprices:[]
    };
  },
  components: {

    PageHeader,
  },
  methods: {
    addEmpJobTitles() {
     // let locale = this.$i18n.locale;
      var data = {
        title: "popups.add",
        inputs: [
        //   {
        //     model: "emp_sector_id",
        //     type: "select",
        //     options: (() => {
        //       return this.emp_sectors.map((data) => {
        //         return {
        //           value: data.id,
        //           label: locale == "ar" ? data.name : data.name_en,
        //         };
        //       });
        //     })(),
        //     label: "Department",
        //   },

          { model: "name", type: "text", label: "popups.name" },
        //   { model: "name_en", type: "text", label: "popups.name_en" },
        ],
        buttons: [
          {
            text: "add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("hr-job-titles", obj,null,true).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editEmpJobTitles(app) {
    //   let locale = this.$i18n.locale;
      var data = {
        title: "popups.edit",
        inputs: [
        //   {
        //     model: "emp_sector_id",
        //     type: "select",
        //     options: (() => {
        //       return this.emp_sectors.map((data) => {
        //         return {
        //           value: data.id,
        //           label: locale == "ar" ? data.name : data.name_en,
        //         };
        //       });
        //     })(),
        //     label: "Department",
        //   },
          {
            model: "name",
            type: "text",
            label: "popups.name",
            value: app.name,
          },
        //   {
        //     model: "name_en",
        //     type: "text",
        //     label: "popups.name_en",
        //     value: app.name_en,
        //   },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("hr-job-titles/", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.EmpJobTitlesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-job-titles/search", { search: this.searchEmpJobTitles })
        .then((res) => {
          this.emp_job_titles = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchEmpJobTitles = "";
      this.EmpJobTitlesSearchMode = false;
      this.get(this.page);
    },
    getEmpJobTitles() {
      this.http.get("hr-job-titles",null,null,true).then((res) => {
        this.emp_job_titles = res.data;
      });
    },
    getEmpSectors() {
      this.http.get("hr-sectors", null, null, true).then((res) => {
        this.emp_sectors = res.data;
      });
    },
    deleteEmpJobTitles(app) {
      var data = {
        title: "popups. deletesms",
        template: `
          <h5>Are you sure you want to delete ?</h5> 
   
        `,
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
            handler: () => {
              console.log(app);
              this.http.delete("hr-job-titles", app.id).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    get(page) {
      console.log(page);
      this.http
        .post("hr-job-titles/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.emp_job_titles = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getEmpSectors();
  },
};
</script>

<template>

    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title=" $t('designation')"
    />
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col"></div>
          <div class="col">
            <button
              type="button"
              @click="addEmpJobTitles()"
              class="btn btn-light float-end"
              data-bs-toggle="modal"
              data-bs-target="#Add"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <div class="table-responsive">
          <table
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="table table-striped table-hover mb-0"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <th scope="col">{{ $t("designations.name") }}</th>
                <th scope="col">{{ $t("designations.created") }}</th>
                <th scope="col">{{ $t("designations.updated") }}</th>
                <th scope="col">{{ $t("designations.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(emp_job_title, index) in emp_job_titles"
                :key="emp_job_title"
                class=""
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? emp_job_title.name
                      : emp_job_title.name_en
                      ? emp_job_title.name_en
                      : emp_job_title.name
                  }}
                </td>
                <td>{{ emp_job_title?.created.split("T")[0] }}</td>
                <td>{{ emp_job_title?.updated.split("T")[0] }}</td>

                <td class="d-flex">
                  <button
                    class="btn btn-primary me-2"
                    @click="editEmpJobTitles(emp_job_title)"
                  >
                    {{ $t("popups.edit") }}
                  </button>
                  <button
                    class="btn btn-danger me-2"
                    @click="deleteEmpJobTitles(emp_job_title)"
                  >
                    {{ $t("popups.delete") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--   Apps  Pagination     -->
  <ul class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page - 1)"
        aria-label="Previous"
      >
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li
      class="page-item"
      :class="{ active: p == page }"
      v-for="p in tot_pages"
      :key="p"
    >
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == tot_pages }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page + 1)"
        aria-label="Next"
      >
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->

</template>
